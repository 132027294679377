import React, { FunctionComponent, useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import happiness from "../images/happiness.mp4"
import rockets from "../images/all-cylinders.mp4"
import successFailure from "../images/success-failure.mp4"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Button from "../components/Button"
import Ghosts from "../components/svg/Ghosts"
import OnScreen from "../components/OnScreen"
import resetLife from "../images/reset-life.gif"
import influencer from "../images/influencers.png"

import reset from "../images/reset.mp4"
import StartButton from "../components/StartButton"
import TypedText from "../components/TypedText"

const GamifyYourLife: FunctionComponent = ({ data }) => {
  const newData = data.allContentfulGamifyYourLife.edges[0].node
  const [mobile, setMobile] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setMobile(true)
    }, 2000)
  }, [])
  return (
    <Layout absoluteHeader>
      <SEO
        description="Unlock fun in daily tasks with our guide on how to gamify your life! Learn strategies for motivation, productivity, and gamifying life!"
        title="Gamify Your Life"
        link="https://mooremomentum.com/gamify-your-life/"
        image="https://mooremomentum.com/static/1b5d3327fe514e605e4715571b39b710/6ea1f/gamify-def.webp"
      />

      <div className="overflow-hidden">
        <div className="primary-gradient-bg pb-16 pt-32 sm:pb-32 sm:pt-40 lg:pt-32">
          <div className="max-w-5xl mx-auto px-4 text-center text-white">
            <h1 className="font-Orbitron mb-8 text-shadow text-3xl md:text-4xl">
              {newData.headline}{" "}
            </h1>

            {/* <div className="my-8">
              <div className="font-Press-Start text-xs uppercase sm:text-sm lg:text-lg">
                <p>{newData.subHeading}</p>
              </div>
            </div> */}
          </div>
        </div>

        <div className="max-w-4xl px-4 -mt-16 mx-auto relative z-10 ">
          <OnScreen
            className="mt-2  md:p-4 red-border"
            classToAdd="scale-in-center"
          >
            <div className="items-center sm:flex mt-6">
              <div className=" sm:flex-1 w-50 sm:mb-0 sm:w-32"></div>
            </div>
          </OnScreen>

          <OnScreen
            className="absolute hidden right-0 -top-28 -z-1 sm:block"
            classToAdd="ghost-slide-lr"
          >
            <Ghosts />
          </OnScreen>
        </div>
        <div className="container mx-auto px-4 md:mb-16 mb-4 pb-18 ">
          <div className="flex items-center md:flex-row flex-col">
            <div className="mr-2" style={{ width: "80px", margin: "0 auto" }}>
              <StaticImage
                alt="Joystick to gamify your life"
                placeholder="blurred"
                src="../images/joystick.png"
                className="mb-12"
              />
            </div>
            <TypedText
              className="font-Press-Start text-xl text-center mx-auto text-coral leading-snug md:w-full"
              text="What if leveling up in life was as fun as playing your favorite game? "
              delay={120}
            />
          </div>
          <p className="text-blue text-xl md:text-2xl text-center text-bold">
            Discover how to gamify your life and turn everyday challenges into
            exciting quests.
          </p>
          <div className="md:mt-0 mt-2 text-center md:text-end block md:hidden">
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <StaticImage
                alt="Gamify Your Life Definition"
                className="equation"
                placeholder="blurred"
                src="../images/gamify.png"
                style={{ width: "60%" }}
              />
            </OnScreen>
          </div>
        </div>

        <div className="bg-gray py-12">
          <div className="container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
            <h2 className="font-Orbitron ml-4 text-center text-3xl lg:text-4xl">
              Gamify Definition and Meaning{" "}
            </h2>

            <p className="text-left">
              Gamification is the art of applying game-design elements and
              principles to non-game contexts. Gamifying your life allows you to
              transform mundane tasks and habit transformation into exciting and
              rewarding challenges to greatly reduce the friction of
              accomplishing goals and building momentum.
            </p>
            <p className="text-left">
              The science of gamification involves harnessing game-like elements
              to help you level up in life; tapping into your brain’s reward
              system to create a positive feedback loop that makes taking those
              crucial actions necessary for personal development not just
              achievable, but genuinely enjoyable.
            </p>

            <div className="flex justify-between flex-col-reverse md:flex-row">
              <div
                className="text-left"
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  listStyle: "none",
                }}
              >
                <h3 className="font-Orbitron text-xl xl:text-2xl mb-4">
                  Key Gamification Principles and Techniques{" "}
                </h3>
                <li>🚀 Clear Goals and Rules</li>
                <li>🚀 Immediate Feedback</li>
                <li>🚀 Visible Progress Tracking</li>
                <li>🚀 Rewards and Achievements</li>
                <li>🚀 Levels and Milestones</li>
                <li>🚀 Challenges and Quests</li>
                <li>🚀 Social Interaction and Competition</li>
              </div>
              <div className="text-center md:text-end md:block hidden">
                <OnScreen
                  className="max-w-3xl mx-auto"
                  classToAdd="swirl-in-fwd"
                >
                  <StaticImage
                    alt="Gamify Your Life Definition"
                    className="equation"
                    placeholder="blurred"
                    src="../images/gamify.png"
                    style={{ width: "60%" }}
                  />
                </OnScreen>
              </div>
            </div>
            <p className="text-left">
              The MM System leverages these cool gamification techniques to help
              transform your life by making it simple, fun, and rewarding to
              overcome the challenges standing between you and your destiny.
            </p>
            <p className="text-left">
              Turn your boring to-do list for personal wellness into an exciting
              adventure you can't wait to get going on.
            </p>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/gamify-def.png"
                />
              </div>
            </OnScreen>
          </div>
        </div>
        <div className=" mb-6 bg-white py-12">
          <div className="container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
            <h2 className="font-Orbitron ml-4 text-center text-3xl lg:text-4xl">
              What Game Are You Stuck In? 
            </h2>

            <p className="text-center text-bold text-coral f-18">
              “Life Is Just like a game. First you have to learn the rules, then
              play it better than anyone else.” - <br />
              ALBERT EINSTEIN
            </p>

            <h3 className="font-Orbitron text-xl xl:text-2xl">
              The Broken System{" "}
            </h3>

            <p className="text-left">
              Life, like any game, has rules. To succeed, you'll want to
              understand and harness these rules in order to identify the
              limitations of your current strategies in order to achieve the
              personal goals you’ve set in your own life.
            </p>
            <p className="text-left">
              We all begin at Level 1, full of wonder and enthusiasm, but the
              flawed system we’re born into centered around materialistic,
              dopamine hits like status and wealth keep us trapped in lower
              levels. And we’re forced to endure a continuous overwhelming 
              barrage from companies looking to monetize our attention. To them,
              we’re “users”
            </p>
            <p className="text-left">
              From day one our major influencers such as parents, peers, school,
              media, and society shape our mindset, habits, and happiness, but
              who’s making sure they’re helping and not hurting us. As the late
              great Stan Lee once said:{" "}
              <span className="text-bold text-coral">
                “With great power there must also come great responsibility.”{" "}
              </span>
            </p>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/Influencers.png"
                />
              </div>
            </OnScreen>
            <h3 className="font-Orbitron text-xl xl:text-2xl">
              The Failure Loop{" "}
            </h3>
            <p className="text-left">
              Many of us end up trapped in a "Failure Loop" that can
              significantly hinder our progress towards happiness and success. 
            </p>
            <p className="text-left">
              We take misaligned actions based on flawed beliefs, leading to
              negative outcomes. This feeds a{" "}
              <Link
                className="link"
                to="/blog/why-is-having-a-fixed-mindset-a-hindrance/"
              >
                fixed mindset
              </Link>
              , decreasing our confidence, motivation, and energy. As a result,
              we either repeat the same mistakes or give up entirely,
              compounding the negative impact across all areas of our lives.
            </p>
            <p className="text-left">
              At Moore Momentum, we help you recognize and break free from this
              loop, setting you on a path to sustainable growth and fulfillment.
            </p>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/failure.png"
                />
              </div>
            </OnScreen>
          </div>
        </div>
        <div className="bg-gray py-12">
          <div className="container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
            <h2 className="font-Orbitron ml-4 text-center text-3xl lg:text-4xl">
              Switch to a Better Game To Win At Life: Your 5 Core Areas{" "}
            </h2>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/extralife.png"
                />
              </div>
            </OnScreen>
            <p className="text-left">
              To switch from “user” to “player” will require us to ensure we
              center our game design around the{" "}
              <Link className="link" to="/5-core-areas-of-life/">
                5 Core Areas of Life
              </Link>
              , which serve as the foundation for sustainable happiness and
              growth.
            </p>
            <p className="text-left">
              Imagine your life as a powerful rocket, poised to break free from
              the gravitational pull of old habits and societal expectations.
              Each of your cores acts as a thruster for your life's engine, and
              your mission is to continually build and maintain balance in all 5
              Cores for a steady trajectory toward your ultimate goal of growth
              and happiness. 
            </p>
            <div className="page-text">
              <h3 className="font-Orbitron text-lg mb-1">
                {" "}
                THE 5 CORE{" "}
                <Link className="link" to="/5-core-areas-of-life/">
                  AREAS OF LIFE
                </Link>
              </h3>
              <div className="flex items-center mt-2">
                <div
                  className="mr-2 mobile-icons-rest"
                  style={{ width: "40px" }}
                >
                  <StaticImage
                    alt="5 areas of life - mindset core of life"
                    placeholder="blurred"
                    src="../images/5-areas-of-life-brain.png"
                  />
                </div>
                <p className="text-left">
                  <b>Mindset Core (Your rocket's guidance system):</b> Develop a
                  "growth owner mindset" that fails forward, sees obstacles as
                  temporary, and confronts fear head-on.
                </p>
              </div>

              <div className="flex items-center  mt-2">
                <div
                  className="mr-2 mobile-icons-rest"
                  style={{ width: "40px" }}
                >
                  <StaticImage
                    alt="5 areas of life - financial core of life"
                    placeholder="blurred"
                    src="../images/dollar.png"
                  />
                </div>
                <p className="text-left">
                  <b>Career and Finance Core: (your fuel reserve):</b> Earn a
                  living doing what you love, execute your purpose, and grow
                  your wealth exponentially.{" "}
                </p>
              </div>

              <div className="flex items-center  mt-2">
                <div className="mr-2 mobile-icons" style={{ width: "40px" }}>
                  <StaticImage
                    alt="5 areas of life - communication core of life"
                    placeholder="blurred"
                    src="../images/5-areas-of-life-communication.png"
                  />
                </div>
                <p className="text-left">
                  <b>Relationships Core (your support crew):</b> Create deep,
                  fulfilling relationships and gain allies to help achieve your
                  goals.
                </p>
              </div>
              <div className="flex items-center ">
                <div
                  className="mr-2 mobile-icons-dumble"
                  style={{ width: "45px" }}
                >
                  <StaticImage
                    alt="Will Moore Pixelated"
                    placeholder="blurred"
                    src="../images/dumble.png"
                  />
                </div>
                <p className="text-left">
                  <b>Physical Health Core (your structural integrity):</b> Take
                  care of your physical self to lose weight, sleep well, and
                  stay healthy in order to gain the energy and stamina to propel
                  you through life.{" "}
                </p>
              </div>
              <div className="flex items-center  mt-2">
                <div
                  className="mr-2 mobile-icons-rest"
                  style={{ width: "40px" }}
                >
                  <StaticImage
                    alt="5 areas of life - emotional core of life"
                    placeholder="blurred"
                    src="../images/5-areas-of-life-yoga.png"
                  />
                </div>
                <p className="text-left">
                  <b>
                    Emotional and Mental Health Core (your life support system):
                  </b>
                  Manage stress effectively, express your passions, and make the
                  world better for having you in it.
                </p>
              </div>
            </div>
            <p className="text-left">
              This holistic approach helps you upgrade your life across all
              dimensions
            </p>
            <h3 className="font-Orbitron text-lg mb-1">
              The Success Loop: Your Epic Quest to Space Journey Success
            </h3>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/failureloopvasuccessloop.webp"
                />
              </div>
            </OnScreen>
            <p className="text-left">
              As you focus on balancing and improving your 5 Cores with Moore
              Momentum, you'll break free from the failure loop and enter your
              "success loop." This is when your rocket breaks free from Earth's
              gravitational pull and achieves a self-sustaining orbit.
            </p>
            <p className="text-left">
              The Success Loop operates as a powerful momentum-building cycle.
              As you take principle-centered actions across your 5 Cores, you'll
              see tangible progress, fueling your growth-owner mindset. This
              boost in confidence and motivation drives you to take more aligned
              actions, creating a self-reinforcing cycle of improvement.
            </p>
            <p className="text-left">
              This is how you start winning at life - by creating a
              self-reinforcing cycle of growth and achievement.
            </p>
            <h3 className="font-Orbitron text-lg mb-1">
              The Equation of Life: Fueling Your Cosmic Journey{" "}
            </h3>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/equation-of-life-v2.jpg"
                />
              </div>
            </OnScreen>
            <p className="text-left">
              With Moore Momentum, your journey is guided by the "Equation of
              Life": Belief System + Repeated Actions + Time = Who You Will
              Become.
            </p>
            <div
              className="text-left"
              style={{ fontSize: "18px", fontWeight: "600", listStyle: "none" }}
            >
              <li>
                🚀 <b>Your belief system</b> acts as the navigation system,
                guiding your rocket. 
              </li>
              <li>
                🚀 <b>Your repeated actions</b> are the thrusters, propelling
                you forward.
              </li>
              <li>
                🚀 <b>Time</b> is the vast expanse through which your rocket
                travels, allowing small actions to compound into significant
                habits and changes. 
              </li>
              <li>
                🚀 <b>Who you will become</b> is the result - allowing you to
                reach any destination in the universe you seek. 
              </li>
            </div>
            <p className="text-left">
              Your habits act as the fuel cells for each of your core thrusters.
              The more bad habits you're able to REPLACE with healthy habits in
              each core, the more fuel you'll create, and the more momentum
              you'll build.
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: `<video className="app__backgroundVideo" controls loop muted  playsinline>
      <source src=${successFailure} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
              }}
              className="equation bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg slide-in-left"
              style={{ width: "70%", margin: "0 auto" }}
            />
            <p className="text-left">
              This momentum is crucial for breaking free from the gravitational
              pull of old patterns and soaring into new realms of possibility.
            </p>
          </div>
        </div>
        <div className=" mb-6 bg-white py-12">
          <div className="container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
            <h2 className="font-Orbitron ml-4 text-center text-3xl lg:text-4xl">
              How to Gamify Your Life: A Step-by-Step Guide{" "}
            </h2>
            <p className="text-left md:mb-4 mb-0">
              The MM System has a 3 step process to help you determine WHO you
              are, WHAT you want, and HOW to get it.
            </p>
            <div className="gap-4 grid grid-cols-1 sm:grid-cols-4 xl:gap-10 lg:grid-cols-3 ">
              <OnScreen
                className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__repeat-2"
                classToAdd="scale-in-center slide"
              >
                <>
                  <div className="bg-white h-full p-6 relative rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                    <div className="flex items-center mb-8">
                      <div className="flex h-14 icon-gray-bg items-center justify-center p-3 rounded-xl w-14">
                        <StaticImage
                          alt="Tedx"
                          className="w-32 sm:w-auto"
                          src="../images/rocket.webp"
                          style={{ transform: "rotate(40deg" }}
                        />
                      </div>

                      <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                        AWARENESS <br />
                        <span className="font-Orbitron text-black text-xl ">
                          <b>WHO</b> you are
                        </span>
                        {/* WHO you are */}
                      </h3>
                    </div>

                    <p>
                      <div className="text-link text-black">
                        Gain awareness of where you stand in each of the 5 Core
                        Areas of Happiness, and determine the bad habits to
                        replace in each.
                      </div>
                    </p>
                  </div>
                </>
              </OnScreen>

              <OnScreen
                className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-3s"
                classToAdd="scale-in-center"
              >
                <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                  <div className="flex items-center mb-8">
                    <div className="flex h-14 icon-teal-bg items-center justify-center p-3 rounded-xl w-14">
                      <svg
                        width="42"
                        height="41"
                        viewBox="0 0 42 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.2736 3.3085e-05C26.7112 0.00979757 31.6483 2.15257 35.2933 5.63594L38.2239 2.70537C39.4645 1.46479 41.5857 2.34343 41.5857 4.09792V15.0981C41.5857 16.1857 40.7041 17.0674 39.6164 17.0674H28.6163C26.8618 17.0674 25.9832 14.9462 27.2237 13.7055L30.6495 10.2797C28.117 7.90844 24.84 6.59491 21.3585 6.56488C13.7768 6.4994 7.38601 12.635 7.45157 20.4684C7.51376 27.8993 13.5382 34.1347 21.2362 34.1347C24.6109 34.1347 27.8003 32.9303 30.3138 30.7248C30.703 30.3834 31.2908 30.4042 31.6569 30.7702L34.9113 34.0247C35.3111 34.4244 35.2913 35.0762 34.8718 35.4551C31.2634 38.7144 26.4816 40.699 21.2362 40.699C9.99755 40.699 0.886801 31.5883 0.886719 20.3497C0.886637 9.12391 10.0478 -0.0200703 21.2736 3.3085e-05Z"
                          fill="#00A98F"
                        />
                      </svg>
                    </div>

                    <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                      VISION <br />
                      <span className="font-Orbitron text-black text-xl ">
                        <b>WHAT</b> you want
                      </span>
                      {/* WHAT you want */}
                    </h3>
                  </div>
                  <p>
                    <div className="text-link text-black">
                      Paint a vivid picture of the fully upgraded version of
                      yourself, and determine the personalized success habits in
                      each core to take you there.
                    </div>
                  </p>
                </div>
              </OnScreen>

              <OnScreen
                className="sm:col-span-2 sm:col-start-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-5s"
                classToAdd="scale-in-center"
              >
                <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                  <div className="flex items-center mb-8">
                    <div className="flex h-14 icon-yellow-bg items-center justify-center p-3 rounded-xl w-14">
                      <svg
                        width="46"
                        height="41"
                        viewBox="0 0 46 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M43.8787 5.08738H35.6117V1.90777C35.6117 0.850546 34.7611 0 33.7039 0H12.0825C11.0253 0 10.1748 0.850546 10.1748 1.90777V5.08738H1.90777C0.850546 5.08738 0 5.93793 0 6.99515V11.4466C0 14.2844 1.78853 17.2017 4.92045 19.4513C7.4244 21.2557 10.4689 22.4004 13.6644 22.766C16.1604 26.9075 19.0777 28.6165 19.0777 28.6165V34.3398H15.2621C12.4561 34.3398 10.1748 35.9853 10.1748 38.7913V39.7452C10.1748 40.2698 10.604 40.699 11.1286 40.699H34.6578C35.1824 40.699 35.6117 40.2698 35.6117 39.7452V38.7913C35.6117 35.9853 33.3303 34.3398 30.5243 34.3398H26.7087V28.6165C26.7087 28.6165 29.626 26.9075 32.122 22.766C35.3255 22.4004 38.37 21.2557 40.866 19.4513C43.9899 17.2017 45.7864 14.2844 45.7864 11.4466V6.99515C45.7864 5.93793 44.9359 5.08738 43.8787 5.08738ZM7.89339 15.3257C5.95382 13.9267 5.08738 12.3687 5.08738 11.4466V10.1748H10.1907C10.2701 12.7661 10.6517 15.0396 11.2081 17.0268C10.0078 16.6135 8.88702 16.0411 7.89339 15.3257ZM40.699 11.4466C40.699 12.7264 39.2921 14.3162 37.893 15.3257C36.8994 16.0411 35.7706 16.6135 34.5703 17.0268C35.1268 15.0396 35.5083 12.7661 35.5878 10.1748H40.699V11.4466Z"
                          fill="#FFC629"
                        />
                      </svg>
                    </div>

                    <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl text-break">
                      ACTION <br />
                      <span className="font-Orbitron text-black text-xl ">
                        <b>HOW</b> to get it
                      </span>
                      {/* HOW to get it */}
                    </h3>
                  </div>

                  <p>
                    <div className="text-link text-black">
                      Embrace a fun, simple, and ethically addicting system that
                      makes you WANT to build and sustain momentum in life.
                    </div>
                  </p>
                </div>
              </OnScreen>
            </div>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/incremental.png"
                />
              </div>
            </OnScreen>
            <h3 className="font-Orbitron text-lg mb-1">
              Winning at Life: Gamification Strategies for Long-Term Success{" "}
            </h3>
            <p className="text-left md:mb-4 mb-0">
              The Moore Momentum System is based around the following key
              components:
            </p>
            <div className="gap-4 grid grid-cols-1 sm:grid-cols-4 xl:gap-10 lg:grid-cols-3 ">
              <OnScreen
                className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__repeat-2"
                classToAdd="scale-in-center slide"
              >
                <>
                  <div className="bg-white h-full p-6 relative rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                    <div className="flex items-center mb-8">
                      <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                        AI Personalization{" "}
                      </h3>
                    </div>

                    <p>
                      <div className="text-link text-black">
                        Our advanced AI technology allows you to experience the
                        benefits of gamification tailored specifically to your
                        needs and preferences.". It analyzes your habits and
                        progress across the 5 Core Areas, providing personalized
                        recommendations, challenges, and rewards. This ensures
                        an engaging and effective journey that adapts as you
                        grow, keeping you motivated and on track towards
                        success.
                      </div>
                    </p>
                  </div>
                </>
              </OnScreen>

              <OnScreen
                className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-3s"
                classToAdd="scale-in-center"
              >
                <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                  <div className="flex items-center mb-8">
                    <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                      Leveraging Universal Principles{" "}
                    </h3>
                  </div>
                  <p>
                    <div className="text-link text-black">
                      We help you identify and apply time-proven principles so
                      you can be confident in the actions you're taking and
                      habits you're forming. These universal truths form the
                      foundation of lasting personal growth and success across
                      all 5 Core Areas of your life.
                    </div>
                  </p>
                </div>
              </OnScreen>

              <OnScreen
                className="sm:col-span-2 sm:col-start-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-5s"
                classToAdd="scale-in-center"
              >
                <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                  <div className="flex items-center mb-8">
                    <h3 className="font-Orbitron  text-blue text-xl xl:text-2xl">
                      The Power of Incremental Progress
                    </h3>
                  </div>

                  <p>
                    <div className="text-link text-black">
                      We emphasize the importance of simple, small, consistent
                      actions, ensuring you don’t try to take too much on at
                      once to allow your actions to compound via The Equation of
                      Life. This approach makes sustainable progress achievable
                      and helps you build momentum over time.
                      <br />
                      Progress bars can be a useful tool to help track your
                      achievements and motivate you as you aim to improve your
                      cooking skills and lose weight.
                    </div>
                  </p>
                </div>
              </OnScreen>
            </div>
          </div>
        </div>
        <div className="bg-gray py-12">
          <div className="container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
            <h2 className="font-Orbitron ml-4 text-center text-3xl lg:text-4xl">
              Press Reset To Begin Your New Game{" "}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: `<video className="app__backgroundVideo" autoplay controls loop muted  playsinline>
      <source src=${reset} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
              }}
              className="equation bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg slide-in-left"
              style={{ width: "70%", margin: "0 auto" }}
            />
            <p className="text-left">
              Ready to gamify your goals and start winning in life?
            </p>
            <p className="text-left">
              Your epic quest, should you choose to accept it, is to fire on all
              cylinders across the 5 core areas of life where the toughest tasks
              become temporary asteroids to maneuver around, and failures become
              learning quests to upgrade your rocket.
            </p>
            <p className="text-left">
              Experience ethically addictive dopamine hits as you earn upgrades
              and custom rewards on screen that transfer into real life!
            </p>
            <p className="text-left">
              Remember, winning at life won’t be easy - no fun game worth
              playing ever is. But with Moore Momentum, you have a powerful ally
              in your corner allowing you to gain the confidence, skills, and
              habits to win in life.
            </p>
            <p className="text-left">
              Imagine starting the final level of Super Mario Brothers without
              the necessary upgrades and knowledge—you’d lose in 30 seconds.
              Life is similar; to gamify it effectively, you need to
              incrementally progress step by step, learning and growing along
              your journey to explore all the wonders the universe has to offer.
            </p>
            <p className="text-left">
              Are you ready to press reset and begin your new game? Join Moore
              Momentum today and start your journey to a gamified, fulfilled
              life! Incorporating elements of a role-playing game can make this
              process even more fun and engaging.
            </p>
            <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
              <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/controller.jpg"
                />
              </div>
            </OnScreen>
            <div className="flex items-center justify-center  mt-2 m-auto">
              <Link to="/core-values-quiz/" target="_blank">
                <img
                  className="m-auto"
                  src={resetLife}
                  alt="areas of life"
                  loading="lazy"
                  style={{ width: "50%" }}
                />
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="container mx-auto px-4">
          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="text-center w-full">
              <div className="hidden lg:block">
                <OnScreen
                  className="bg-blue max-w-3xl mx-auto p-4 rounded-md shadow-lg"
                  classToAdd="slide-in-left"
                >
                  <div className="bg-white p-4">
                    <StaticImage
                      alt="Vertical Loop of Success for happiness hacks"
                      placeholder="blurred"
                      src="../images/success-loop-vertical.png"
                    />
                  </div>
                </OnScreen>
              </div>

              <div className="lg:hidden">
                <OnScreen
                  className="bg-blue p-2 rounded-md shadow-lg sm:p-4"
                  classToAdd="slide-in-left"
                >
                  <div className="bg-white p-1 sm:p-4">
                    <StaticImage
                      alt="Horizontal Loop of Success for gamifying your life"
                      placeholder="blurred"
                      src="../images/success-loop-horizontal.png"
                    />
                  </div>
                </OnScreen>
              </div>
            </div>

            <div>
              <OnScreen classToAdd="slide-in-right">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.secondSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron text-xl font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>

          <hr className="bg-blue border-none h-0.5 my-12 lg:bg-transparent" />

          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="lg:order-2 text-center w-full">
              <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
                <StaticImage
                  alt="Gamify Your Life Definition"
                  placeholder="blurred"
                  src="../images/gamify.png"
                />
              </OnScreen>
            </div>

            <div>
              <OnScreen classToAdd="slide-in-left">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.thirdSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>

          <hr className="bg-blue border-none h-0.5 my-12 lg:bg-transparent" />

          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="text-center w-full">
              <OnScreen
                className="mt-8 text-center"
                classToAdd="scale-in-center"
              >
                <div className="bg-blue inline-block p-4 rounded-md shadow-lg">
                  <StaticImage
                    alt="Gamify Your Life Definition"
                    placeholder="blurred"
                    src="../images/gamify-def.png"
                  />
                </div>
              </OnScreen>
            </div>

            <div>
              <OnScreen classToAdd="slide-in-right">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.fourthSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em className="stan">{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron text-xl font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>

          <hr className="bg-blue border-none h-0.5 my-12 lg:bg-transparent" />

          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="lg:order-2 text-center w-full">
              <OnScreen
                className="border-blue max-w-2xl mx-auto p-4 "
                classToAdd="swirl-in-fwd"
              >
                <StaticImage
                  alt="Gamify Your Life"
                  placeholder="blurred"
                  src="../images/core.png"
                />
                <div
                  style={{
                    display: "flex",
                    width: "55%",
                    margin: "0 auto",
                    alignItems: "center",
                  }}
                >
                  <StaticImage
                    alt="Gamify Your Life"
                    placeholder="blurred"
                    src="../images/rocket.webp"
                  />
                </div>
              </OnScreen>
            </div>

            <div>
              <OnScreen classToAdd="slide-in-left">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.sixthSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em className="stan">{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>

          <hr className="bg-blue border-none h-0.5 my-12 lg:bg-transparent" />

          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="text-center w-full">
              <OnScreen
                className="bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg"
                classToAdd="slide-in-left"
              >
                <div className="bg-gray">
                  <StaticImage
                    alt="Gamify Your Life"
                    placeholder="blurred"
                    src="../images/kid-core-gamify-life.png"
                  />{" "}
                </div>
              </OnScreen>
            </div>

            <div>
              <OnScreen classToAdd="slide-in-right">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.seventhSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron text-xl font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>

          <hr className="bg-blue border-none h-0.5 my-12 lg:bg-transparent" />

          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="lg:order-2 text-center w-full">
              <OnScreen className="max-w-3xl mx-auto" classToAdd="swirl-in-fwd">
                <StaticImage
                  alt="Gamify Your Life"
                  placeholder="blurred"
                  src="../images/Emmett-Happy-Town-V2.png"
                />
                <video
                  autoPlay
                  loop
                  muted
                  playsinline=""
                  id="vid"
                  preload="auto"
                >
                  <source src={rockets} type="video/mp4" />
                </video>
              </OnScreen>
            </div>

            <div>
              <OnScreen classToAdd="slide-in-left">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.eightSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>

          <hr className="bg-blue border-none h-0.5 my-12 lg:bg-transparent" />

          <div className="gap-8 lg:grid flex flex-col-reverse flex-wrap items-center lg:grid-cols-2">
            <div className="text-center w-full">
              <OnScreen
                className="bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg"
                classToAdd="slide-in-left"
              >
                <div className="bg-gray">
                  <video
                    autoPlay
                    loop
                    muted
                    playsinline=""
                    id="vid"
                    preload="auto"
                  >
                    <source src={reset} type="video/mp4" />
                  </video>
                  <StaticImage
                    alt="Equation of Life to gamify your life"
                    placeholder="blurred"
                    src="../images/equation-of-life.png"
                  />
                  <video
                    autoPlay
                    loop
                    muted
                    playsinline=""
                    id="vid"
                    preload="auto"
                  >
                    <source src={happiness} type="video/mp4" />
                  </video>
                </div>
              </OnScreen>
            
            </div>
            <div>
              <OnScreen classToAdd="slide-in-right">
                <div className="space-y-4 text-link">
                  {renderRichText(newData.ninthSection, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.OL_LIST]: (node, children) => (
                        <ul
                          className="topic-list pl-4"
                          style={{ listStyleType: "none" }}
                        >
                          {children}
                        </ul>
                      ),
                      [BLOCKS.UL_LIST]: (node, children) => (
                        <ul
                          className="topic-list pl-4"
                          style={{ listStyleType: "none" }}
                        >
                          {children}
                        </ul>
                      ),
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,

                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron text-xl font-Orbitron mb-8 relative text-2xl md:text-3xl">
                          {children}
                        </h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </OnScreen>
            </div>
          </div>
        </div> */}
        <OnScreen classToAdd="slide-in-right">
          <div className="container mt-20 mb-12 mx-auto px-10 md:mb-24">
            <div className="grid rounded-md shadow-lg space">
              {/* <StaticImage
                alt="App Background Screen"
                className="space-bg rounded-md"
                layout="fullWidth"
                // objectPosition="67% 67%"
                placeholder="blurred"
                quality={100}
                src="../images/index-app-bg.png"
                style={{ gridArea: "1/1" }}
              /> */}

              <div className="grid relative" style={{ gridArea: "1/1" }}>
                <div className="absolute bg-black bg-opacity-30 inset-0 rounded-md" />

                <div className="absolute bottom-0 hidden left-0 z-0 md:block md:w-36 xl:w-full">
                  <StaticImage
                    alt="Alien"
                    className="w-32"
                    placeholder="blurred"
                    src="../images/alien.png"
                  />
                </div>

                <div className="gap-16 grid grid-cols-1 pt-8 px-8 text-white z-10 md:gap-4 md:grid-cols-2 md:pt-0 md:px-0">
                  <div className="max-w-md mx-auto space-y-8 z-10 md:max-w-3xl md:pl-8 md:pt-8 2xl:self-center">
                    <h2 className="font-Orbitron text-2xl lg:text-4xl">
                      Learn more about the Gamify Your Life App{" "}
                    </h2>

                    <p>
                      Whether it’s our{" "}
                      <a href="https://habitscoach.ai" target="_blank">
                        Habitscoach.ai
                      </a>{" "}
                      for Phase 1 of your journey, or our{" "}
                      <Link to="/weekly-habit-tracker-app/">
                        weekly habit tracker
                      </Link>
                      productivity app for phase 2, our AI driven health tech is
                      aimed at reducing the friction of those daily tasks
                      required to build self-discipline  allows you to truly
                      gamify life by making it fun and ethically addicting to
                      overcome real life obstacles and put your skill sets to
                      the test on your mission to save yourself in order to save
                      the world!
                    </p>
                    <p>
                      As you earn rewards and level up on screen with our
                      gamification system, you’ll also level up in “REAL LIFE.”
                    </p>
                    <p>
                      Ready to gamify your life? Take the first step by learning
                      the rules of lifestyle gamification.
                    </p>
                    <div className="mb-12" style={{ marginBottom: "30px" }}>
                      <Button href="/weekly-habit-tracker-app/" link="GATSBY">
                        Learn More
                      </Button>
                    </div>
                  </div>

                  <div className="flex items-end justify-center sm:max-w-xs sm:mx-auto">
                    <StaticImage
                      alt="Iphone App Splash Screen"
                      placeholder="blurred"
                      src="../images/app-mock-index.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OnScreen>
        <div className=" container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
          <h3 className="text-center">
            <b className="text-coral font-Press-Start mb-8 text-xl lg:text-2xl">
              {" "}
              Every time you earn rewards and level up on screen with our
              system,
            </b>
            <br />
            <b className="text-coral font-Press-Start text-xl lg:text-2xl">
              you'll also level up in "REAL LIFE!"
            </b>
          </h3>
          <p>
            If you’re serious about leveling up in all 5 Areas of Life, start by
            taking our Core Values Quiz. This quiz will help you identify which
            areas need the most attention and guide you on your path to success.
          </p>
          <p>
            Your results will provide you with the insights needed to begin your
            journey to gamify your life and achieve balance across all key
            areas.
          </p>
        </div>
        <OnScreen
          className="max-w-5xl mb-18 mt-8 mx-auto px-4 lg:mb-18 lg:mt-12"
          classToAdd="slide-in-left"
        >
          <div className="flex justify-center mock-shadow">
            <svg
              className="hidden w-auto sm:block sm:h-64 lg:h-72 2xl:h-80"
              width="198"
              height="518"
              viewBox="0 0 198 518"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M190.5 15V7.5H183H115C55.6294 7.5 7.5 55.6294 7.5 115V403C7.5 462.371 55.6294 510.5 115 510.5H183H190.5V503V15Z"
                fill="#55BEE0"
                stroke="black"
                strokeWidth="15"
              />
              <circle
                className="animate-pulse"
                cx="116.5"
                cy="137.5"
                r="39.5"
                fill="black"
              />
              <circle cx="112" cy="240" r="19" fill="black" />
              <circle cx="77" cy="273" r="19" fill="black" />
              <circle cx="147" cy="273" r="19" fill="black" />
              <circle cx="112" cy="309" r="19" fill="black" />
              <rect
                x="125"
                y="359"
                width="31"
                height="31"
                rx="3"
                fill="black"
              />
            </svg>

            <div className="bg-blue flex flex-1 flex-col justify-center rounded-md px-4 py-8 text-center text-white sm:rounded-none">
              <div>
                <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
                  Ready to Learn the Rules to Lifestyle Gamification?
                </h2>

                <div className="mt-8">
                  <StartButton className="bg-white py-3 px-12 rounded-full text-blue" />
                </div>
              </div>
            </div>

            <svg
              className="hidden w-auto sm:block sm:h-64 lg:h-72 2xl:h-80"
              width="198"
              height="518"
              viewBox="0 0 198 518"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 15V7.5H15H83C142.371 7.5 190.5 55.6294 190.5 115V403C190.5 462.371 142.371 510.5 83 510.5H15H7.5V503V15Z"
                fill="#E3675B"
                stroke="black"
                strokeWidth="15"
              />
              <circle
                className="animate-pulse"
                cx="83.5"
                cy="288.5"
                r="39.5"
                fill="black"
              />
              <circle cx="83" cy="117" r="19" fill="black" />
              <circle cx="48" cy="150" r="19" fill="black" />
              <circle cx="118" cy="150" r="19" fill="black" />
              <circle cx="83" cy="186" r="19" fill="black" />
              <rect x="36" y="359" width="31" height="31" rx="3" fill="black" />
            </svg>
          </div>
        </OnScreen>

        {/* <div className="max-w-7xl mx-auto mb-16 px-4">
          <div className="bg-coral px-4 py-8 relative rounded-xl shadow-lg">
            <div className="mx-auto text-center text-white md:w-2/3 text-link">
              {renderRichText(newData.mission, {
                renderMark: {
                  [MARKS.BOLD]: text => (
                    <span className="font-bold">{text}</span>
                  ),
                  [MARKS.ITALIC]: text => <em>{text}</em>,
                },
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <p className="text-xl">{children}</p>
                  ),
                  [BLOCKS.HEADING_1]: (node, children) => (
                    <h1 className="font-Orbitron text-2xl">{children}</h1>
                  ),
                  [BLOCKS.HEADING_2]: (node, children) => (
                    <h2 className="font-Orbitron text-xl font-Orbitron mb-8 relative text-2xl md:text-3xl">
                      {children}
                    </h2>
                  ),
                  [BLOCKS.HEADING_3]: (node, children) => (
                    <h3 className="font-Orbitron text-lg">{children}</h3>
                  ),
                  [BLOCKS.EMBEDDED_ASSET]: node => {
                    if (!node.data.target) {
                      return
                    }
                    // console.log("Imagess",node.data.target.gatsbyImageData)
                    const { gatsbyImageData } = node.data.target
                    if (!gatsbyImageData) {
                      // asset is not an image
                      return null
                    }
                    return (
                      <figure className="flex flex-wrap justify-center items-center">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </figure>
                    )
                  },
                },
              })}
            </div>

            <OnScreen
              className="absolute right-0 -top-24 transform translate-y-48 -z-1"
              classToAdd="ghost-slide-in"
            >
              <Ghosts />
            </OnScreen>
          </div>
        </div> */}

        <div className="max-w-xl mx-auto my-16 px-4 text-center md:my-16 lg:max-w-7xl lg:text-left">
          <div className="mb-16 text-center">
            <p className="font-Orbitron text-xl mb-4">
              Checkout My Youtube Channel Dedicated To Gamifying Your Life
            </p>
            <p className="mb-6">
              For more tips, strategies, and insights on how to gamify your
              life, check out my YouTube channel. Here, you'll find a wealth of
              resources to help you master the art of lifestyle gamification.
            </p>

            <Button
              className="animate-pulse"
              href="https://www.youtube.com/channel/UCBBnybi_zZquxqB9rZIP6Zw"
              link="DEFAULT"
            >
              Checkout My Youtube Channel
            </Button>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:grid-cols-2">
            {mobile && (
              <div>
                <OnScreen
                  className="bg-blue p-4 rounded-md shadow-lg"
                  classToAdd="rotate-in-cw"
                >
                  <div className="wistia_responsive_padding relative iframe-wrapper">
                    <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
                      <div className="wistia_embed wistia_async_oj0z7xkky7 videoFoam=true h-full relative w-full">
                        <div className="wistia_swatch h-full left-0 opacity-1 overflow-hidden absolute top-0 transition-opacity width-full">
                          <img
                            src="https://fast.wistia.com/embed/medias/oj0z7xkky7/swatch"
                            className="filter blur-sm h-full object-contain width-full"
                            alt=""
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </OnScreen>
              </div>
            )}

            <div>
              <OnScreen
                className="bg-blue p-4 rounded-md shadow-lg"
                classToAdd="rotate-in-ccw"
              >
                {mobile && (
                  <div className="wistia_responsive_padding relative iframe-wrapper">
                    <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
                      <div className="wistia_embed wistia_async_n6d7oij7af videoFoam=true h-full relative w-full">
                        <div className="wistia_swatch h-full left-0 opacity-1 overflow-hidden absolute top-0 transition-opacity width-full">
                          <img
                            src="https://fast.wistia.com/embed/medias/n6d7oij7af/swatch"
                            className="filter blur-sm h-full object-contain width-full"
                            alt=""
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </OnScreen>
            </div>
          </div>

          <p className="mt-16 max-w-2xl mx-auto text-center text-link">
            {renderRichText(newData.fifthSection, {
              renderMark: {
                [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
                [MARKS.ITALIC]: text => <em>{text}</em>,
              },
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                [BLOCKS.HEADING_1]: (node, children) => (
                  <h1 className="font-Orbitron text-2xl">{children}</h1>
                ),
                [BLOCKS.HEADING_2]: (node, children) => (
                  <h2 className="font-Orbitron text-xl font-Orbitron mb-8 relative text-2xl md:text-3xl">
                    {children}
                  </h2>
                ),
                [BLOCKS.HEADING_3]: (node, children) => (
                  <h3 className="font-Orbitron text-lg">{children}</h3>
                ),
                [BLOCKS.EMBEDDED_ASSET]: node => {
                  if (!node.data.target) {
                    return
                  }
                  // console.log("Imagess",node.data.target.gatsbyImageData)
                  const { gatsbyImageData } = node.data.target
                  if (!gatsbyImageData) {
                    // asset is not an image
                    return null
                  }
                  return (
                    <figure className="flex flex-wrap justify-center items-center">
                      <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                    </figure>
                  )
                },
              },
            })}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const data = graphql`
  query GamifyYourLife {
    allContentfulGamifyYourLife {
      edges {
        node {
          headline
          firstSection {
            raw
          }
          subHeading
          secondSection {
            raw
          }
          thirdSection {
            raw
          }
          fourthSection {
            raw
          }
          fifthSection {
            raw
          }
          sixthSection {
            raw
          }
          seventhSection {
            raw
          }
          eightSection {
            raw
          }
          ninthSection {
            raw
          }
          mission {
            raw
          }
        }
      }
    }
  }
`

export default GamifyYourLife
